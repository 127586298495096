import { Fragment } from "react";
import styles from "./SignUp.module.scss";

import useCountryCodes from "../../custom-hooks/useCountryCodes";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
// import { postDataWithoutToken } from "../../apis/apiMethods";
// import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import { yupValidators } from "../../helpers/yupValidators";
// import { setUserInfo } from "../../helpers/setUserInfo";
import useBusinessSectors from "../../custom-hooks/useBusinessSectors";
//@ts-ignore
import getUnicodeFlagIcon from "country-flag-icons/unicode";
// import ROUTES from "../../helpers/ROUTES";
// import { useAuth } from "../../Context/auth.context";
// import { setLoginInfo } from "../../helpers/setLoginInfo";
import advanclyLogo from "../../img/advancly_logo.svg";
import sparkImageOnboard from "../../img/sparkOverview.svg";

import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import useBusinessTypes from "../../custom-hooks/useBusinessTypes";
export default function SignUpThree({
  setStep,
  setError,
  error,
  personalEmail,
  setReqBodyData,
  state,
}: any) {
  const { data: otpResponseData } = state;
  const schema = yup.object().shape({
    biz_name: yupValidators.businessName,
    // +    aggregator_type: yupValidators.businessName,
    country_code: yupValidators.countryCode,
    //@ts-ignore
    first_name: !otpResponseData?.isExistingUser && yupValidators.firstName,
    //@ts-ignore

    last_name: !otpResponseData?.isExistingUser && yupValidators.lastName,
    personal_phone: yupValidators.phoneNumber,
    businessSector: yupValidators.businessCategory,
    dialCode: yupValidators.dialCode,
    IsLenderTrue: yupValidators.lenderTrue,
    lending_time: yupValidators.lendingTime,
    business_type_id: yupValidators.business_type_id,
  });
  const { register, handleSubmit, errors, watch } = useForm({
    resolver: yupResolver(schema),
  });
  const isCustomerLender = watch("IsLenderTrue", false);
  const {
    data: sectorsData,
    // status: sectorsStatus,
    error: sectorsError,
  } = useBusinessSectors();

  const { data: businessTypes } = useBusinessTypes();

  const onSubmit = ({
    biz_name,
    country_code,
    first_name,
    last_name,
    personal_phone,
    businessSector,
    dialCode,
    IsLenderTrue,
    lending_time,
    business_type_id,
  }: any) => {
    setError(false);
    // setLoading(true);
    const reqBody = {
      first_name:
        otpResponseData?.isExistingUser && otpResponseData?.user?.first_name
          ? otpResponseData?.user?.first_name
          : first_name,
      last_name:
        otpResponseData?.isExistingUser && otpResponseData?.user?.last_name
          ? otpResponseData?.user?.last_name
          : last_name,
      country_code,
      biz_name,
      personal_email: personalEmail,
      personal_phone: `${personal_phone}`,
      sector_id: Number(businessSector),
      dial_code: dialCode,
      is_existing_user: otpResponseData?.isExistingUser
        ? otpResponseData?.isExistingUser
        : false,
      is_lender: IsLenderTrue,
      city: "Ikeja",
      state_name: "Lagos",
      lending_experience_in_years:
        lending_time === undefined ? 0 : lending_time,
      business_type_id: business_type_id,
    };
    if (reqBody.country_code === "other_country") {
      setReqBodyData(reqBody);
      setStep(6);
    } else {
      setReqBodyData(reqBody);
      setStep(4);
    }

    // setLoading(false);
    // try {
    //   const reqBody = {
    //     aggregator_type,
    //     first_name,
    //     last_name,
    //     password,
    //     country_code,
    //     biz_name,
    //     personal_email: personalEmail,
    //     personal_phone,
    //     sector_id: Number(businessSector),
    //     dial_code: dialCode,
    //     IsLenderTrue,
    //   };
    //   const response = await postDataWithoutToken(
    //     apiEndpoints.signUpUrl,
    //     reqBody,

    //     true // Boolean here means that this endpoint should call production URL only
    //   );
    //   // This sets everything in the sessionStorage / localStorage
    //   setUserInfo(response, true); // true signifies that user is on sso by default

    //   const { aggregatorResult, eligibilityResult } = await setLoginInfo();
    //   setAggregatorDetails(aggregatorResult);
    //   setEligibilityData(eligibilityResult);
    //   setLoading(false);
    //   history.push(ROUTES.GET_STARTED);
    // } catch (error) {
    //   setLoading(false);
    //   setError(errorHandler(error));
    // }
  };
  const {
    data: countryCodes,
    error: countryCodesError,
    isLoading: isLoadingCountries,
  } = useCountryCodes();

  return (
    <main role="main" className={`bg-white ${styles.signUpLayout}`}>
      {countryCodesError && (
        <p className="alert alert-danger small mb-0">
          {errorHandler(countryCodesError)}
        </p>
      )}

      <div className={styles.left}>
        <Fragment>
          <Link className={styles.sparkLogoLink} to="/login">
            <img
              className={styles.sparkLogo}
              src={advanclyLogo}
              alt="Login logo"
            />
          </Link>
          <div className={`${styles.onboardingBackButton} mt-3`}>
            {" "}
            <small
              className="color-mid-blue  cursor-pointer font-weight-bold  "
              onClick={() => {
                setStep(2);
                setError(false);
              }}
            >
              &#8592; Go back
            </small>
          </div>

          {/* Form section  */}
          <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <h3 className="color-blue-hover-none font-weight-bold mb-1">
              Create your account
            </h3>
            <p className="color-card-border-color" style={{ color: "#170D33" }}>
              Enter your business details
            </p>
            {otpResponseData?.isExistingUser && (
              <div
                className="alert alert-primary alert-dismissible fade show"
                role="alert"
              >
                <small>
                  We noticed you already have an account, please fill the
                  required details
                </small>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
            {error && <p className="alert alert-danger small mb-4">{error}</p>}
            {sectorsError && (
              <p className="alert alert-danger small">
                {errorHandler(sectorsError)}
              </p>
            )}
            <div className="row">
              {/* <div className="col-12">
                <CustomInputField
                  type="email"
                  maxLength="128"
                  reference={register}
                  name="personalEmail"
                  placeholder="hello@ricknmorty.com"
                  label="Email address"
                  disableAutoComplete={true}
                  disabled={personalEmail}
                  defaultValue={personalEmail}
                />
              </div> */}
              {/* <div className="col-md-6">
                <CustomSelectDropdown
                  reference={register}
                  name="aggregator_type"
                  errors={errors.aggregator_type}
                  label="Aggregator Type"
                >
                  <option value="Lending">Lending</option>
                  <option value="Investment">Investment</option>
                  <option value="Both">Both</option>
                </CustomSelectDropdown>
              </div> */}
              <div className="col-12">
                <CustomInputField
                  type="text"
                  maxLength={128}
                  ref={register}
                  name="biz_name"
                  placeholder="Enter your business name"
                  label="Business Name"
                  errors={errors.biz_name}
                  disableAutoComplete={true}
                />
              </div>
              <div className="col-12">
                <CustomInputField
                  type="text"
                  maxLength={30}
                  ref={register}
                  placeholder="Enter your first name"
                  label="First Name"
                  name="first_name"
                  defaultValue={
                    otpResponseData?.isExistingUser &&
                    otpResponseData?.user?.first_name
                      ? otpResponseData?.user?.first_name
                      : ""
                  }
                  disabled={
                    otpResponseData?.isExistingUser &&
                    otpResponseData?.user?.first_name
                      ? true
                      : false
                  }
                  errors={errors.first_name}
                  disableAutoComplete={true}
                />
              </div>
              <div className="col-12">
                <CustomInputField
                  type="text"
                  maxLength={30}
                  ref={register}
                  placeholder="Enter your last name"
                  label="Last Name"
                  name="last_name"
                  defaultValue={
                    otpResponseData?.isExistingUser &&
                    otpResponseData?.user?.last_name
                      ? otpResponseData?.user?.last_name
                      : ""
                  }
                  disabled={
                    otpResponseData?.isExistingUser &&
                    otpResponseData?.user?.last_name
                      ? true
                      : false
                  }
                  errors={errors.last_name}
                  disableAutoComplete={true}
                />
              </div>
              <div className="col-4">
                <CustomSelectDropdown
                  label="Business"
                  ref={register}
                  name="dialCode"
                  errors={errors.personal_phone || errors.dialCode}
                  style={{ borderRadius: "10px" }}
                >
                  {" "}
                  <option value="">Choose</option>
                  {countryCodes?.map(
                    ({
                      dial_code,
                      country_code,
                    }: {
                      dial_code: string;
                      country_code: string;
                    }) => {
                      return (
                        <option value={dial_code} key={dial_code}>
                          {`${getUnicodeFlagIcon(country_code)} ${dial_code}`}
                        </option>
                      );
                    }
                  )}
                </CustomSelectDropdown>
              </div>
              <div className="col-8">
                <CustomInputField
                  type="number"
                  label="  Phone Number"
                  maxLength={20}
                  ref={register}
                  name="personal_phone"
                  placeholder="07011111111"
                  errors={errors.personal_phone || errors.dialCode}
                  disableAutoComplete={true}
                  extraLabel={
                    isLoadingCountries ? "Loading countries..." : null
                  }
                >
                  {/* <select
                    ref={register}
                    name="dialCode"
                    style={{ borderRadius: "10px",  }}
                  >
                    <option value="">Choose</option>
                    {countryCodes?.map(({ dial_code, country_code }) => {
                      return (
                        <option value={dial_code} key={dial_code}>
                          {`${getUnicodeFlagIcon(country_code)} ${dial_code}`}
                        </option>
                      );
                    })}
                  </select> */}
                </CustomInputField>
              </div>
              <div className="col-12">
                <CustomSelectDropdown
                  ref={register}
                  name="country_code"
                  errors={errors.country_code}
                  label="What country is your business located?"
                >
                  <option value="">Select Country</option>
                  {countryCodes &&
                    countryCodes.map(
                      (
                        country: { country_code: string; name: string },
                        index: number
                      ) => {
                        const { country_code, name } = country;
                        return (
                          <option value={country_code} key={index}>
                            {name}
                          </option>
                        );
                      }
                    )}
                  <option value="other_country">Other Countries</option>
                </CustomSelectDropdown>
              </div>
              <div className="col-12">
                <CustomSelectDropdown
                  ref={register}
                  label="Select Business Sector"
                  name="businessSector"
                  errors={errors.businessSector}
                  showRequiredIcon={true}
                >
                  <option value="">Select Sector</option>
                  {sectorsData && sectorsData.length
                    ? sectorsData.map(
                        (sector: {
                          category_id: number;
                          category_name: string;
                        }) => {
                          const { category_id, category_name } = sector;
                          return (
                            <option key={category_id} value={category_id}>
                              {category_name}
                            </option>
                          );
                        }
                      )
                    : null}
                </CustomSelectDropdown>
              </div>
              <div className="col-12">
                <CustomSelectDropdown
                  ref={register}
                  label="Select Business Type"
                  name="business_type_id"
                  errors={errors.business_type_id}
                  showRequiredIcon={true}
                >
                  <option value="">Select Business Type</option>
                  {businessTypes &&
                    businessTypes?.length &&
                    businessTypes?.map((_businessType: any) => {
                      const { id, name } = _businessType;
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })}
                </CustomSelectDropdown>
              </div>

              <div className="col-12">
                <label className="form-label text-muted ">
                  Are you a lender?
                </label>{" "}
                <br />
                <div className={styles.flexRadio}>
                  <div className={styles.button}>
                    <input
                      type="radio"
                      id="lenderYes"
                      name="IsLenderTrue"
                      value={"true"}
                      ref={register}
                    />
                    <label className="btn btn-default" htmlFor="lenderYes">
                      Yes
                    </label>
                  </div>
                  <div className={styles.button}>
                    <input
                      type="radio"
                      id="lenderNo"
                      name="IsLenderTrue"
                      value={"false"}
                      ref={register}
                    />
                    <label className="btn btn-default" htmlFor="lenderNo">
                      No
                    </label>
                  </div>
                </div>
                <div>
                  {errors.IsLenderTrue && (
                    <ErrorHandler errors={errors.IsLenderTrue} />
                  )}
                </div>
              </div>
              <div className="col-12 mt-2">
                {isCustomerLender === "true" && (
                  <CustomInputField
                    type="number"
                    name="lending_time"
                    maxLength={30}
                    min={1}
                    ref={register}
                    placeholder="Enter year number"
                    label="How long have you been lending(year)?"
                    errors={errors.lending_time}
                    disableAutoComplete={true}
                  ></CustomInputField>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 flex-wrap">
              <button
                type="submit"
                className="btn advancly-btn btn-sm transition-3d-hover"
                // disabled={loading}
              >
                Proceed
                {/* {loading && <LoaderSignUp />} */}
              </button>
            </div>
          </form>
        </Fragment>
      </div>
      <div className={styles.right}>
        <div className={styles.content}>
          <img
            className={styles.illustrator}
            src={sparkImageOnboard}
            alt="Login Illustration"
          />
          <h3 className={styles.imgHeading}>
            Discover endless business possibilities with Spark
          </h3>
          <p className={styles.imgParagraph}>
            Register now for fast, tailored credit. <br /> Start your financial
            journey today!
          </p>
          <div className={styles.highlightContainer}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </main>
  );
}
