import React from "react";
// @ts-ignore

import { createRoot } from "react-dom/client";

import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

import App from "./App";
import "./style/index.scss";

const isProduction = process.env.NODE_ENV === "production";
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={isProduction}
    isVerboseMode={true}
    loadingComponent={null}
  >
    <App />
  </CacheBuster>
);
