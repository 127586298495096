import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Paginator from "../../components/Paginator/Paginator.component";
import { useLocation, withRouter } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import useWalletTransactions from "../../custom-hooks/useWalletTransactions";
import { formatMoney } from "../../helpers/formatter";
import OnboardingBackButtonComponent from "../../components/OnboardingBackButton/OnboardingBackButton.component";
import Loader from "../../components/Loader/Loader.component";
import { appInsights } from "../../components/AppInsight/AppInsight";
import CustomInputField from "../../components/CustomHTMLElements/CustomInputField";

interface Irequest {
  transactionDate: string;
  transactionRemarks: string;
  debitAmount: number;
  creditAmount: number;
  transactionStatus: string;
}
function WalletTransactions() {
  const location = useLocation();
  const { data: locationData, consumerType } = location.state as any;

  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    accountNumber: locationData?.accountNumber,
    consumerType: consumerType,
  });
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    startDate: "",
    endDate: "",
    accountNumber: locationData?.accountNumber,
    consumerType: consumerType,
  });
  const [count, setCount] = useState<any>(null);
  const { data, status, error } = useWalletTransactions(preState);

  if (data) {
    if (count === null) {
      setCount(data?.total);
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const filterData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletTransactions.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      {error && <p className="alert alert-danger small">{error as string}</p>}
      {!error && (
        <div>
          <div className="d-flex align-items-center justify-content-start">
            <h3 className="page-title d-flex align-items-center">
              <OnboardingBackButtonComponent />
              Wallet Transactions
            </h3>
          </div>
          <div className="advancly-wrapper-container">
            {status === "loading" ? (
              <div className=" p-3">
                <PageLoader />
              </div>
            ) : (
              <div className=" p-3">
                <div className="d-flex justify-content-between align-items-center py-3 advancly-sub-wrapper">
                  <form
                    className="d-flex flex-row flex-wrap"
                    onSubmit={filterData}
                  >
                    <div className="form-group mr-3">
                      <div className="input-group mt-2">
                        <label className="mr-2 mt-2">From </label>
                        <CustomInputField
                          type="date"
                          name="startDate"
                          value={state.startDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mr-3 mt-2">
                      <div className="input-group">
                        <label className="mr-2 mt-2">To </label>
                        <CustomInputField
                          type="date"
                          name="endDate"
                          value={state.endDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn advancly-btn  mt-2"
                        value="submit"
                        //@ts-ignore
                        disabled={status === "loading"}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className="advancly-sub-wrapper mt-3">
                  <h3 className="page-subtitle  mb-3">
                    Available Balance:{" "}
                    {
                      //@ts-ignore

                      status === "loading" ? (
                        <Loader />
                      ) : data?.availableBalance ? (
                        formatMoney(data?.availableBalance, data?.currency)
                      ) : (
                        "0.00"
                      )
                    }
                  </h3>
                  <div className="table-responsive">
                    <table className="table-style">
                      <thead className="">
                        <tr>
                          <th className="th-td-style th-style ">S/N</th>
                          <th className="th-td-style th-style ">
                            Transaction Date
                          </th>
                          <th className="th-td-style th-style ">Narration</th>
                          <th className="th-td-style th-style ">Debit</th>
                          <th className="th-td-style th-style ">Credit</th>
                          <th className="th-td-style th-style ">
                            Transaction Status
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {data?.transactions?.length > 0 ? (
                          data?.transactions?.map(
                            (request: Irequest, _id: number) => {
                              const {
                                transactionDate,
                                transactionRemarks,
                                debitAmount,
                                creditAmount,
                                transactionStatus,
                              } = request;
                              return (
                                <tr key={_id}>
                                  <td className="th-td-style ">{_id + 1}</td>
                                  <td className="th-td-style ">
                                    {transactionDate.substring(0, 10)}
                                  </td>
                                  <td className="th-td-style ">
                                    {transactionRemarks}
                                  </td>

                                  <td className="th-td-style ">
                                    {formatMoney(debitAmount, data?.currency)}
                                  </td>
                                  <td className="th-td-style ">
                                    {formatMoney(creditAmount, data?.currency)}
                                  </td>
                                  <td className="th-td-style ">
                                    {transactionStatus}
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <NoTableDataAvailable />
                        )}
                      </tbody>
                    </table>
                  </div>
                  {data && data?.transactions && (
                    <Paginator
                      size={preState.size}
                      page={preState.pageNumber}
                      count={count}
                      changeCurrentPage={(pageNumber) =>
                        setPreState({ ...preState, pageNumber })
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default withRouter(WalletTransactions);
