import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
// import * as yup from "yup";
import { postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import Loader from "../Loader/Loader.component";
// import { yupValidators } from "../../helpers/yupValidators";
import styles from "./SignUp.module.scss";
import advanclyLogo from "../../img/advancly_logo.svg";
import sparkImageOnboard from "../../img/sparkOverview.svg";
import LoaderSignUp from "../LoaderSignUp/LoaderSignUp";
import { appInsights } from "../AppInsight/AppInsight";

// const schema = yup.object().shape({
//   otp: yupValidators.otp,
// });

const SignupTwo = ({
  setStep,
  state,
  setLoading,
  setError,
  loading,
  error,
}: any) => {
  const [otpLoading, setOtpLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));

  // const { register, handleSubmit, errors } = useForm({
  //   resolver: yupResolver(schema),
  // });
  const handleChange = (element: any, index: number) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const otpFinal = otp.join("").toString();
  const onSubmit = async () => {
    setLoading(true);
    setError(null);
    const { personalEmail } = state;
    const req = { email: personalEmail, otp: otpFinal };
    try {
      await postData(
        apiEndpoints.VALIDATE_OTP,
        req,
        true // Boolean here means that this endpoint should call production URL only
      );
      setLoading(false);
      setStep(3);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "SignupTwo.tsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
      setOtp(new Array(6).fill(""));
    }
  };
  const resetOTP = async () => {
    setOtpLoading(true);
    setError(null);
    const { personalEmail } = state;
    try {
      await postData(
        apiEndpoints.RESET_OTP + "?email=" + personalEmail + "&countryCode=NA"
      );
      setOtpLoading(false);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "SignupTwo.tsx" },
      });
      setOtpLoading(false);
      setError(errorHandler(error));
    }
  };

  return (
    <main role="main" className={`bg-white ${styles.signUpLayout}`}>
      <div className={styles.left}>
        <Fragment>
          <Link className={styles.sparkLogoLink} to="/login">
            <img
              className={styles.sparkLogo}
              src={advanclyLogo}
              alt="Login logo"
            />
          </Link>

          <div className={`${styles.onboardingBackButton} mt-3`}>
            {" "}
            <small
              className="color-mid-blue  cursor-pointer font-weight-bold  "
              onClick={() => {
                setStep(1);
                setError(false);
              }}
            >
              &#8592; Go back
            </small>
          </div>
          {/*form section*/}
          <form className="my-3">
            <h3 className="font-weight-bold mb-1  color-blue-hover-none ">
              Enter OTP sent to
            </h3>
            <p className="">
              {state.personalEmail}{" "}
              <span
                className="cursor-pointer"
                style={{ color: "#713FFF" }}
                onClick={() => {
                  setStep(1);
                }}
              >
                Change Email?
              </span>{" "}
            </p>
            {error && <p className="alert alert-danger small mb-4">{error}</p>}
            {/* <div className="row">
              <div className="col-md-12">
                <CustomInputField
                  type="number"
                  maxLength={6}
                  reference={register}
                  label="OTP"
                  placeholder="Enter OTP"
                  name="otp"
                  errors={errors.otp}
                />
              </div>
            </div> */}
            <div className={`row pb-4 pt-4 mr-3 ${styles.mainOtpDiv}`}>
              {otp.map((data, index) => {
                return (
                  <input
                    className={` ${styles.OtpStyling} col ml-4 pt-2 pb-2 pl-2 pr-2 text-center `}
                    type="text"
                    name="otp"
                    key={index}
                    maxLength={1}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onFocus={(e) => {
                      e.target.select();
                      setError(null);
                    }}
                  />
                );
              })}
            </div>

            <div className="d-flex justify-content-between align-items-center my-4 flex-wrap">
              <p className="d-flex flex-wrap">
                Didn’t get OTP?
                <span
                  className="color-blue cursor-pointer"
                  onClick={resetOTP}
                  style={{ paddingLeft: "3px", color: "#713fff" }}
                >
                  Resend {otpLoading && <Loader />}
                </span>
              </p>
              <button
                type="button"
                className="btn advancly-btn btn-sm transition-3d-hover"
                onClick={onSubmit}
                disabled={loading || otpLoading}
              >
                Validate
                {loading && <LoaderSignUp />}
              </button>
            </div>
          </form>
        </Fragment>
      </div>
      <div className={styles.right}>
        <div className={styles.content}>
          <img
            className={styles.illustrator}
            src={sparkImageOnboard}
            alt="Login Illustration"
          />
          <h3 className={styles.imgHeading}>
            Discover endless business possibilities with Spark
          </h3>
          <p className={styles.imgParagraph}>
            Register now for fast, tailored credit. <br /> Start your financial
            journey today!
          </p>
          <div className={styles.highlightContainer}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignupTwo;
