import React, { useEffect, useState } from "react";
import { IstepFunctions } from "../GetStartedBusinesInfoMain";
import styles from "../../../GetStarted.module.scss";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";
import { ReactComponent as Plus } from "../../../../../img/plus_add.svg";
import ShareholderParentForm from "./ShareholderParentForm";
import GetStartedShareholdersList from "./GetStartedShareholdersList";
import GetStartedAddChildShareholderModal from "./GetStartedAddChildShareholderModal";
import useGetShareholders from "../../../../../custom-hooks/useGetShareholders";
import { useAuth } from "../../../../../Context/auth.context";

const GetStartedShareHolder = ({
  handlePrev,
  handleNext,
  scrollToTop,
}: IstepFunctions) => {
  const [isAddParentShareholder, setIsAddParentShareholder] =
    useState<boolean>(false);
  const [parentShareholderLoading, setParentShareholderLoading] =
    useState<boolean>(false);
  const [parentShareholderError, setParentShareholderError] = useState<
    string | null
  >("");
  const [parentShareholderSuccess, setParentShareholderSuccess] = useState<
    string | null
  >(null);
  const [editShareholderTable, setEditShareholderTable] =
    useState<boolean>(false);
  const [fullShareholderData, setFullshareholderData] = useState<any>();
  const { aggregatorDetails } = useAuth();

  const {
    data: shareholdersListData,
    isLoading: shareholdersListLoading,
    status: shareholderListStatus,
    error: shareholdersListErrors,
  } = useGetShareholders({
    funderId: aggregatorDetails?.aggregator_id,
    Type: null,
  });
  useEffect(() => {
    appInsights.trackPageView({
      name: "GetStartedShareHolder.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div className={`${styles.center_Container}`}>
      <div style={{ width: "11.375rem" }}>
        {" "}
        <small
          className="color-mid-blue  cursor-pointer font-weight-bold  "
          onClick={handlePrev}
        >
          &#8592; Go back
        </small>
      </div>
      <div className={styles.center_form_shareholder}>
        {parentShareholderError && (
          <p className="alert alert-danger small text-center">
            {" "}
            {parentShareholderError}
          </p>
        )}
        {shareholdersListErrors && (
          <p className="alert alert-danger small text-center">
            {" "}
            {shareholdersListErrors}
          </p>
        )}
        {parentShareholderSuccess && (
          <p className="alert alert-success small text-center">
            {" "}
            {parentShareholderSuccess}
          </p>
        )}

        <div>
          <h3 className="color-blue-hover-none">Shareholders</h3>
          <p className="bg-light-grey">
            {shareholdersListData?.length === 0
              ? "Add a shareholder"
              : "Kindly enter the details below"}
          </p>
        </div>
        {shareholdersListData?.length > 0 && (
          <GetStartedShareholdersList
            shareholdersListData={shareholdersListData}
            shareholdersListLoading={shareholdersListLoading}
            shareholderListStatus={shareholderListStatus}
            editShareholderTable={editShareholderTable}
            setEditShareholderTable={setEditShareholderTable}
            fullShareholderData={fullShareholderData}
            setFullshareholderData={setFullshareholderData}
            isAddParentShareholder={isAddParentShareholder}
            setIsAddParentShareholder={setIsAddParentShareholder}
          />
        )}

        {isAddParentShareholder && (
          <div className={`${styles.center_form}`}>
            <ShareholderParentForm
              parentShareholderLoading={parentShareholderLoading}
              setParentShareholderLoading={setParentShareholderLoading}
              setParentShareholderError={setParentShareholderError}
              setParentShareholderSuccess={setParentShareholderSuccess}
              setIsAddParentShareholder={setIsAddParentShareholder}
              shareholdersListData={shareholdersListData}
              isAddParentShareholder={isAddParentShareholder}
              editShareholderTable={editShareholderTable}
              setEditShareholderTable={setEditShareholderTable}
              fullShareholderData={fullShareholderData}
              scrollToTop={scrollToTop}
            />
          </div>
        )}
        {shareholdersListData?.length === 0 && !isAddParentShareholder && (
          <div className="mb-4" onClick={() => setIsAddParentShareholder(true)}>
            <button className="advancly-purple-btn ">
              <Plus />
              <span className="p-2"> Add Shareholder</span>
            </button>
          </div>
        )}
        {!isAddParentShareholder && shareholdersListData?.length > 0 && (
          <div className=" col-4">
            <button
              type="button"
              className="fa fa-plus  advancly-text-btn my-3"
              data-toggle="modal"
              data-target="#addChildShareholderModal"
            >
              <span className="pl-1"> Add Child Shareholder</span>
            </button>
          </div>
        )}
        {!isAddParentShareholder && shareholdersListData?.length > 0 && (
          <div className="d-flex flex-wrap justify-content-start  ">
            <button
              type="button"
              className="advancly-btn  transition-3d-hover m-2"
              onClick={() => setIsAddParentShareholder(true)}
            >
              Add Another Shareholder{" "}
            </button>

            <button
              className={`btn advancly-white-btn  transition-3d-hover m-2 ${
                !isAddParentShareholder ? "ml-4" : ""
              } `}
              type="button"
              onClick={handleNext}
            >
              Next
            </button>
          </div>
        )}
        <GetStartedAddChildShareholderModal
          editShareholderTable={editShareholderTable}
          setEditShareholderTable={setEditShareholderTable}
        />
      </div>
    </div>
  );
};

export default GetStartedShareHolder;
