import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { errorHandler } from "../../helpers/errorHandler";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import Loader from "../Loader/Loader.component";
import { useQueryCache } from "react-query";
import useAggregatorRoles from "../../custom-hooks/useAggregatorRoles";
import styles from "./AddNewUserModal.module.scss";
import { appInsights } from "../AppInsight/AppInsight";
const schema = yup.object().shape({
  email: yupValidators.email,
  phoneNumber: yupValidators.businessPhone,
  firstName: yupValidators.firstName,
  lastName: yupValidators.lastName,
});
const AddNewUserModalContent = ({
  singleSignatory,
  setSingleSignatory,
  setSignatoryType,
  step,
  changeAddNewUserModal,
  addNewUserModal,

  changeSingleSignatory,
  setChangeSingleSignatory,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | boolean | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    setError(null);
    setSuccess(null);
    setLoading(false);
  }, []);

  const dismissRef = useRef<HTMLButtonElement | null>(null);
  const queryCache = useQueryCache();
  const { register, handleSubmit, errors, reset, watch } = useForm({
    resolver: yupResolver(schema),
  });
  const watchCheckboxRole = watch("user_roles", false);

  //   const scrollToTop = () => {
  //     document.getElementById("topOfPage").scrollIntoView({
  //       behavior: "smooth",
  //     });
  //   };

  const onSubmit = async ({
    email,
    phoneNumber,
    firstName,
    lastName,
    user_roles,
  }: {
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    user_roles: string;
  }) => {
    setLoading(true);
    setError(false);

    const reqBody = {
      personal_email: email,
      phone_number: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      roles: user_roles,
    };
    try {
      const res = await postData(
        apiEndpoints.createAggregatorUser,
        reqBody,
        true
      );
      if (res.status === true) {
        setSuccess(
          "New user has been created and can now log in as an aggregator."
        );
        queryCache.invalidateQueries("getAggregatorUsers");
        if (addNewUserModal) {
          // this is to  add new user for setting up signatory for the first time
          queryCache.invalidateQueries("getAggregatorSignatoryUsers");

          setSingleSignatory(singleSignatory + step);
        } else if (changeAddNewUserModal) {
          // this is to  add new user for changing signatory from present signatory

          queryCache.invalidateQueries("getAggregatorSignatoryUsers");
          setChangeSingleSignatory(changeSingleSignatory + step);
        } else {
          reset();
          setTimeout(() => {
            dismissRef.current?.click();
            setSuccess(null);
          }, 3000);
        }
      } else {
        setError(res.message);
      }
      setLoading(false);
      //   scrollToTop();
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "AddNewUserModalContent.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
      //   scrollToTop();
    }
  };
  const { data: aggregatorRoles } = useAggregatorRoles();
  return (
    <div className="modal-content">
      <div className="modal-header ">
        <h5 className="modal-title ">Add New User</h5>
        <button
          type="button"
          className="close "
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            if (addNewUserModal) {
              setSignatoryType("");
            } else if (changeAddNewUserModal) {
              setChangeSingleSignatory(2);
            }
          }}
          // ref={dismissRef}
        >
          &times;
        </button>
      </div>
      <div className="modal-body">
        {addNewUserModal && (
          <small
            className="color-mid-blue text-right  row cursor-pointer font-weight-bold pl-2 pb-2 "
            onClick={() => {
              setSignatoryType("");
            }}
          >
            &#8592; Go back
          </small>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          {error && <p className="alert alert-danger small">{error}</p>}
          {success && <p className="alert alert-success small">{success}</p>}

          <CustomInputField
            type="email"
            maxLength={128}
            ref={register}
            name="email"
            placeholder="morty@ricknmorty.com"
            label="New User's Email address"
            errors={errors.email}
          />
          <CustomInputField
            type="text"
            maxLength={30}
            ref={register}
            label="New User's First Name"
            placeholder="Rick"
            name="firstName"
            errors={errors.firstName}
          />

          <CustomInputField
            type="text"
            maxLength={30}
            ref={register}
            label="New User's Last Name"
            placeholder="Morty"
            name="lastName"
            errors={errors.lastName}
          />
          <CustomInputField
            type="number"
            maxLength={20}
            ref={register}
            name="phoneNumber"
            placeholder="e.g. 07010000001"
            label="New User's Phone Number"
            errors={errors.phoneNumber}
          />
          <label>Assign Role</label>
          {aggregatorRoles?.map((role: { value: string; name: string }) => (
            <div className="" key={role.value}>
              <label htmlFor={role?.value}>
                <input
                  type="checkbox"
                  name="user_roles"
                  className={styles.check}
                  ref={register}
                  value={role?.value}
                />{" "}
                {role?.name}{" "}
              </label>
            </div>
          ))}
          <button
            type="submit"
            value="submit"
            className="btn advancly-btn btn-sm transition-3d-hover w-100 mt-2"
            disabled={loading || watchCheckboxRole.length === 0}
          >
            Submit
            {loading && <Loader />}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddNewUserModalContent;
